import { Order, PosCustomerOrder, PosOrder, PosProviderOrder, ProviderOrder } from '@/model/api/Order';
import { PaginatedResponse } from '@/model/api/PaginatedResponse';
import axios from 'axios';
import { CRUD } from './base/crud';

class OrderService extends CRUD<Order, any> {
    readonly endPoint = `orders`;

    public async providerOrders(providerID: number, params?: any) {
        if (params.filters) {
            params.filters = this.getFilterParam(params.filters);
        }

        this.source = axios.CancelToken.source();
        const cancelToken = this.source.token;

        const res = await this.get<PaginatedResponse<PosProviderOrder>>(
            `providers/${providerID}/orders`, 
            { params, cancelToken }
        );
        
        // res.data = plainToClass(Entity, res.data);
        return res;
    }

    public async posOrders(posID: number, params?: any) {
        if (params.filters) {
            params.filters = this.getFilterParam(params.filters);
        }

        this.source = axios.CancelToken.source();
        const cancelToken = this.source.token;

        const res = await this.get<PaginatedResponse<PosOrder>>(
            `pos/${posID}/orders`, 
            { params, cancelToken }
        );
        
        return res;
    }

    public async posCustomerOrders(posID: number, params?: any) {
        if (params.filters) {
            params.filters = this.getFilterParam(params.filters);
        }

        this.source = axios.CancelToken.source();
        const cancelToken = this.source.token;

        const res = await this.get<PaginatedResponse<PosCustomerOrder>>(
            `pos/${posID}/customer-orders`, 
            { params, cancelToken }
        );
        
        return res;
    }

    public async sendPosFeedback(order: Order) {
        return this.post<any>(`orders/${order.id}/send-pos-feedback`, order);
    }

    public async searchOrder(code: string) {
        this.source = axios.CancelToken.source();
        const cancelToken = this.source.token;

        const res = await this.get<Order>(
            `${this.endPoint}/code/${code}/`, 
            { cancelToken }
        );
        
        return res;
    }

    public updateTechnician(order: Order) {
        return this.post<any>(
            `orders/${order.id}/change-technician`, order
        );
    }
}

export const orderService = new OrderService();



