import { Coupon, CouponStatus } from "@/model/api/Coupon";
import { MainRoutesEnum } from "@/router/MainRoutesEnum";
import { couponService } from "@services/coupon.service";
import { FilterMatchMode } from "primevue/api";
import { Options, Vue } from "vue-class-component";
import { CouponsRoutesEnum } from "../../router";

import {
  DynamicTable,
  MyAutocomplete,
} from "@components"
import { authStore } from "@/modules/auth/store";
import { defineComponent, h } from "vue";
import moment from "moment";
import { UserRoleEnum } from "@/model/enums/UserRoleEnum";

@Options({
  components: {
    DynamicTable,
    MyAutocomplete
  }
})
export default class CouponsPage extends Vue {
  isTableLoading = false;
  totalRecordsLength = 0;
  reloadCount = 0;

  get service() {
    return couponService;
  }

  get empty() {
    return this.$t('coupons.list.empty_message');
  }

  get cols() {
    return [
      {
        field: 'code',
        filterField: 'code',
        header: this.$t('coupons.table.code'),
        style: 'min-width: 10px; max-width: 150px'
      },
      {
        field: 'description',
        filterField: 'description',
        header: this.$t('coupons.table.description'),
      },
      {
        field: 'status',
        header: this.$t('coupons.table.status'),
        style: 'min-width: 10px; max-width: 150px'
      },
      {
        field: 'value',
        filterField: 'value',
        header: this.$t('coupons.table.value'),
        style: 'text-align: right;',    
        headerColspan: 2
      },
      {
        field: 'expiration',
        header: this.$t('coupons.table.expiration'),
      },
    ];
  }

  get datatable(): any {
    return this.$refs.datatable;
  }

  filters = {
    code: {
      value: null,
      matchMode: FilterMatchMode.STARTS_WITH
    },
    description: {
      value: null,
      matchMode: FilterMatchMode.CONTAINS
    },
    status: {
      value: null,
      matchMode: FilterMatchMode.EQUALS
    },
    value: {
      value: null,
      matchMode: FilterMatchMode.EQUALS
    },
  };

  get statusOptions() {  
    return [
      { 
        label: this.$t("coupon.status.issue"), 
        value: CouponStatus.ISSUED 
      },
      { 
        label: this.$t("coupon.status.expir"), 
        value: CouponStatus.EXPIRED 
      },
      { 
        label: this.$t("coupon.status.redee"), 
        value: CouponStatus.REDEEMED 
      },
      { 
        label: this.$t("coupon.status.tbref"), 
        value: CouponStatus.TO_BE_REFUNDED 
      },
      { 
        label: this.$t("coupon.status.refun"), 
        value: CouponStatus.REFUNDED 
      },
      { 
        label: this.$t("coupon.status.error"), 
        value: CouponStatus.ERROR 
      },
    ]
  }
  
  get entityId() {
    if (authStore.getters.userRole === UserRoleEnum.SUPER_ADMIN) {
      return 0;
    }

    return authStore.getters.me?.entity_id;
  }

  customIndexReqTable(params) {
    const clone = JSON.parse(JSON.stringify(params));

    const dateFilter = clone.filters.expiration?.value;

    if (dateFilter) {
      const stringDate = moment(dateFilter).format('YYYY-MM-DD');
      clone.filters.expiration.value = stringDate;
    }

    this.reloadCount++;
    return couponService.fetchCoupons(clone);
  }

  onTableLoadingChange(value: boolean) {
    this.isTableLoading = value;    
  }

  onTableTotalRecordsChange(value: number) {
    this.totalRecordsLength = value;
  }

  goDashboard() {
    this.$router.push({ name: MainRoutesEnum.DASHBOARD });
  }

  goToDetail(model: Coupon) {
    this.$router.push({
      name: CouponsRoutesEnum.COUPON_DETAILS,
      params: { couponId: model.id }
    });
  }

  applyFilters() {
    this.datatable.loadData();
  }

  resetFilters() {
    for (const key in this.filters) {
        this.filters[key].value = null;
    }

    this.applyFilters();
  }
}