import { authStore } from "@/modules/auth/store";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { CouponChoices, CouponEvent, CouponType } from "@/model/api/Coupon";
import { AddCouponStepEnum } from "@/modules/coupons/router/CouponsRoutesEnum";
import { orderService } from "@services/orders.service";
import { customerOrderTypes, Order, OrderStatusEnum, OrderTypeEnum } from "@/model/api/Order";

@Options({
  emits: ['goStep']
})

export default class RefundCouponStep extends Vue {
  @Prop()
  readonly step!: string;

  @Prop()
  readonly dataModel!: CouponEvent;

  async created() {
  }

  get me() {
    return authStore.getters.me;
  }

  get isNextEnabled() {
    return this.dataModel.coupon.order.id;
  }

  goSummary() {
    this.$emit('goStep', AddCouponStepEnum.SUMMARY);
  }

  goChooseType() {
    this.dataModel.coupon.order = new Order();
    this.$emit('goStep', AddCouponStepEnum.CHOOSE_TYPE);
  }

  async searchOrder() {
    try {
      const order = await orderService.searchOrder(this.dataModel.coupon.order.order_code);

      if (order != null) {
        if (!customerOrderTypes.includes(order.type)) {
          this.$errorMessage("", this.$t('coupon.refund_coupon.error.order.type_not_allowed'));
        } else if (order.coupon?.source_order_id){
          this.$errorMessage("", this.$t('coupon.refund_coupon.error.order.refunded'));
        } else {
          this.dataModel.coupon.order = order;
        }  
      }
    } catch (error) {
      console.error(error);
      this.$errorMessage("", this.$t('coupon.refund_coupon.error.order.not_valid'));
    }
  }
}
