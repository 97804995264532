import { authStore } from "@/modules/auth/store";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { CouponChoices, CouponEvent, CouponType } from "@/model/api/Coupon";
import { AddCouponStepEnum } from "@/modules/coupons/router/CouponsRoutesEnum";

@Options({
  emits: ['goStep']
})

export default class ChooseTypeStep extends Vue {
  @Prop()
  readonly step!: string;

  @Prop()
  readonly dataModel!: CouponEvent;

  couponTypes: CouponChoices[];

  async created() {
    this.couponTypes = [
      {
        type: CouponType.PROMO, 
        name: this.$t('coupon.type.promo')
      }, {
        type: CouponType.REFUND, 
        name: this.$t('coupon.type.refun')}
    ];
  
  }

  get me() {
    return authStore.getters.me;
  }

  get selectedType() {
    return this.couponTypes.find(ct => ct.type == this.dataModel?.coupon?.type) || null;
  }

  set selectedType(value) {
    this.dataModel.coupon.type = value.type;
  }

  get isNextEnabled() {
    return this.dataModel.coupon.type && this.dataModel.coupon.description;
  }

  goPromotionalCoupon() {
    this.$emit('goStep', AddCouponStepEnum.PROMO_COUPON);
  }

  goRefundCoupon() {
    this.$emit('goStep', AddCouponStepEnum.REFUND_COUPON);
  }
}
