import { toDisplayString as _toDisplayString, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = {
  id: "coupon_page",
  class: "page standard"
}
const _hoisted_2 = { class: "header" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = {
  key: 1,
  class: "content card"
}
const _hoisted_6 = { class: "p-mb-5 p-px-3" }
const _hoisted_7 = { class: "list-card p-col-12" }
const _hoisted_8 = { class: "p-d-flex p-jc-between p-ai-center p-field" }
const _hoisted_9 = { class: "field_value" }
const _hoisted_10 = { class: "p-d-flex p-jc-between p-ai-center p-field" }
const _hoisted_11 = { class: "field_value" }
const _hoisted_12 = { class: "p-d-flex p-jc-between p-ai-center p-field" }
const _hoisted_13 = { class: "field_value" }
const _hoisted_14 = { key: 1 }
const _hoisted_15 = { class: "p-d-flex p-jc-between p-ai-center p-field" }
const _hoisted_16 = { class: "field_value" }
const _hoisted_17 = { key: 1 }
const _hoisted_18 = { class: "p-d-flex p-jc-between p-ai-center p-field" }
const _hoisted_19 = { class: "field_value" }
const _hoisted_20 = { class: "p-d-flex p-jc-between p-ai-center p-field" }
const _hoisted_21 = { class: "field_value" }
const _hoisted_22 = { class: "p-d-flex p-jc-between p-ai-center p-field" }
const _hoisted_23 = { class: "field_value" }
const _hoisted_24 = { class: "p-d-flex p-jc-between p-ai-center p-field" }
const _hoisted_25 = { class: "field_value" }
const _hoisted_26 = { class: "p-d-flex p-jc-between p-ai-center p-field" }
const _hoisted_27 = { class: "field_value" }
const _hoisted_28 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Divider = _resolveComponent("Divider")!
  const _component_Button = _resolveComponent("Button")!
  const _component_DateFormatted = _resolveComponent("DateFormatted")!
  const _component_CurrencyText = _resolveComponent("CurrencyText")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("header", _hoisted_2, [
      (_ctx.issued)
        ? (_openBlock(), _createBlock("div", _hoisted_3, [
            _createVNode("h1", null, _toDisplayString(_ctx.$t("coupons.issued.list.title")), 1),
            _createVNode("p", null, _toDisplayString(_ctx.$t("coupons.issued.list.subtitle")), 1)
          ]))
        : (_openBlock(), _createBlock("div", _hoisted_4, [
            _createVNode("h1", null, _toDisplayString(_ctx.$t("coupons.list.title")), 1),
            _createVNode("p", null, _toDisplayString(_ctx.$t("coupons.list.subtitle")), 1)
          ]))
    ]),
    (_ctx.requestPending)
      ? (_openBlock(), _createBlock(_Fragment, { key: 0 }, [
          _createTextVNode(_toDisplayString(_ctx.$t('loading_label')), 1)
        ], 64))
      : (_openBlock(), _createBlock("section", _hoisted_5, [
          _createVNode("header", _hoisted_6, [
            _createVNode("div", _hoisted_7, [
              _createVNode("h2", null, _toDisplayString(_ctx.$t("coupons.details.title", {code: _ctx.coupon?.code})), 1),
              _createVNode("span", {
                innerHTML: _ctx.$t("coupons.details.subtitle")
              }, null, 8, ["innerHTML"])
            ]),
            _createVNode(_component_Divider)
          ]),
          _createVNode("form", {
            id: "edit_form",
            onSubmit: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleSubmit($event, _ctx.doSave))),
            class: "p-px-3"
          }, [
            _createVNode("div", _hoisted_8, [
              _createVNode("label", null, _toDisplayString(_ctx.$t('coupons.detail.code')), 1),
              _createVNode("div", _hoisted_9, _toDisplayString(_ctx.coupon?.code || '-'), 1)
            ]),
            _createVNode("div", _hoisted_10, [
              _createVNode("label", null, _toDisplayString(_ctx.$t('coupons.detail.description')), 1),
              _createVNode("div", _hoisted_11, _toDisplayString(_ctx.coupon?.description || '-'), 1)
            ]),
            _createVNode("div", _hoisted_12, [
              _createVNode("label", null, _toDisplayString(_ctx.$t('coupons.detail.source_order.code')), 1),
              _createVNode("div", _hoisted_13, [
                (_ctx.coupon?.source_order?.order_code)
                  ? (_openBlock(), _createBlock(_component_Button, {
                      key: 0,
                      class: "p-button",
                      icon: "pi pi-euro",
                      iconPos: "right",
                      onClick: _ctx.goSourceOrder,
                      label: _ctx.coupon?.source_order?.order_code
                    }, null, 8, ["onClick", "label"]))
                  : (_openBlock(), _createBlock("div", _hoisted_14, "-"))
              ])
            ]),
            _createVNode("div", _hoisted_15, [
              _createVNode("label", null, _toDisplayString(_ctx.$t('coupons.detail.order.code')), 1),
              _createVNode("div", _hoisted_16, [
                (_ctx.coupon?.order?.order_code)
                  ? (_openBlock(), _createBlock(_component_Button, {
                      key: 0,
                      class: "p-button",
                      icon: "pi pi-euro",
                      iconPos: "right",
                      onClick: _ctx.goOrder,
                      label: _ctx.coupon?.order?.order_code
                    }, null, 8, ["onClick", "label"]))
                  : (_openBlock(), _createBlock("div", _hoisted_17, _toDisplayString(_ctx.$t('coupons.detail.order.not_used')), 1))
              ])
            ]),
            _createVNode("div", _hoisted_18, [
              _createVNode("label", null, _toDisplayString(_ctx.$t('coupons.detail.service')), 1),
              _createVNode("div", _hoisted_19, _toDisplayString(_ctx.coupon?.service?.name || _ctx.$t('coupons.detail.service.all_services')), 1)
            ]),
            _createVNode("div", _hoisted_20, [
              _createVNode("label", null, _toDisplayString(_ctx.$t('coupons.detail.where')), 1),
              _createVNode("div", _hoisted_21, _toDisplayString(_ctx.coupon?.where?.business_name || _ctx.$t('coupons.detail.where.everywhere')), 1)
            ]),
            _createVNode("div", _hoisted_22, [
              _createVNode("label", null, _toDisplayString(_ctx.$t('coupons.detail.status')), 1),
              _createVNode("div", _hoisted_23, _toDisplayString(_ctx.$t(`coupon.status.${_ctx.coupon?.status.toLowerCase()}`)), 1)
            ]),
            _createVNode("div", _hoisted_24, [
              _createVNode("label", null, _toDisplayString(_ctx.$t('coupons.detail.expiration')), 1),
              _createVNode("div", _hoisted_25, [
                _createVNode(_component_DateFormatted, {
                  value: _ctx.coupon?.expiration,
                  format: _ctx.dateFormat
                }, null, 8, ["value", "format"])
              ])
            ]),
            _createVNode("div", _hoisted_26, [
              _createVNode("label", null, _toDisplayString(_ctx.$t('coupons.detail.value')), 1),
              _createVNode("div", _hoisted_27, [
                (_ctx.coupon?.value_type == 'AMOUN')
                  ? (_openBlock(), _createBlock(_component_CurrencyText, {
                      key: 0,
                      value: _ctx.coupon?.value
                    }, null, 8, ["value"]))
                  : (_openBlock(), _createBlock("div", _hoisted_28, _toDisplayString(_ctx.coupon?.value || 0) + "%", 1))
              ])
            ])
          ], 32)
        ])),
    _createVNode("div", null, [
      _createVNode(_component_Button, {
        label: _ctx.$t('btn_go_back_label'),
        class: "p-button-secondary p-mt-6",
        icon: "pi pi-arrow-left",
        iconPos: "left",
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$router.back()))
      }, null, 8, ["label"])
    ])
  ]))
}