import { Coupon } from "@/model/api/Coupon";
import { couponService } from "@services/coupon.service";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { CouponsRoutesEnum } from "../../router";
import VueQrcode from 'vue-qrcode'
import { OrdersRoutesEnum } from "@/modules/orders/router";

@Options({
  components: {
    VueQrcode,
  }
})
export default class CouponPage extends Vue {
  @Prop() readonly couponId!: String;

  coupon: Coupon = null; 

  get dateFormat() {
    return "DD/MM/YYYY";
  }

  private loadCoupon() {
    return this.$waitFor(async () => {
      this.coupon = await couponService.getById(+this.couponId);
    });
  }

  created() {
    if (!this.couponId) {
      this.$router.replace({ name: CouponsRoutesEnum.COUPONS});
      return;
    }

    this.loadCoupon();
  }

  goSourceOrder() {
    this.$router.push({ name: OrdersRoutesEnum.ORDER_DETAILS, params: { orderId: this.coupon?.source_order.id } });
  }

  goOrder() {
    this.$router.push({ name: OrdersRoutesEnum.ORDER_DETAILS, params: { orderId: this.coupon?.order.id } });
  }

}