import { BaseApiModel } from "@/model/common/BaseApiModel";
import { Order } from "./Order";

export class Coupon extends BaseApiModel {
    id: number = null;
    type: string = null;
    status: CouponStatus = null;
    expiration: string = null;
    value: number = 0;
    value_type: string = null;
    description: string = null;
    source_order_id: number = null;
    order_id: number = null;
    source_id: number = null;
    source_type: CouponSourceType = null;
    target_id: number = null;
    target_type: CouponTargetType = null;
    where_id: number = null;
    where_type: CouponWhereType = null;
    code: string = null;

    order: Order = new Order();
    source_order: Order = new Order();
    greyed: boolean = false;
}

export class CouponEvent extends BaseApiModel {
    codes?: number[] = null;
    coupon: Coupon = new Coupon();
}

export class CouponChoices {
    type: string = null;
    name: string = null;
}

export enum CouponSourceType {
    POS = 'POS00',
    PROVIDER = 'PROVI',
    CUSTOMER = 'CUSTO',
    SPONSOR = 'SPONS',
}

export enum CouponTargetType {
    POS = 'POS00',
    CUSTOMER = 'CUSTO',
}

export enum CouponWhereType {
    POS = 'POS00',
}

export enum ValueType {
    AMOUNT = "AMOUN",
    PERCENT = "PERCE"
}

export enum CouponStatus {
    ISSUED = "ISSUE",
    EXPIRED = "EXPIR",
    EXPIRATION_1 = "EXP_1",
    EXPIRATION_2 = "EXP_2",
    REDEEMED = "REDEE",
    TO_BE_REFUNDED = "TBREF",
    REFUNDED = "REFUN",
    ERROR = "ERROR"
}

export enum CouponType {
    PROMO = "PROMO",
    REFUND = "REFUN"
}