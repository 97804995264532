import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "step-title" }
const _hoisted_2 = {
  key: 0,
  class: "card"
}
const _hoisted_3 = { class: "step-actions" }
const _hoisted_4 = { class: "step-actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Divider = _resolveComponent("Divider")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode("label", _hoisted_1, _toDisplayString(_ctx.$t('pos.services.add.summary_step.title')), 1),
    (_ctx.step == 'summary')
      ? (_openBlock(), _createBlock("div", _hoisted_2, [
          _createVNode("div", _hoisted_3, [
            _createVNode(_component_Button, {
              label: _ctx.$t('btn_step_back_label'),
              class: "p-button-secondary",
              icon: "pi pi-angle-double-left",
              iconPos: "left",
              onClick: _ctx.goBack
            }, null, 8, ["label", "onClick"]),
            _createVNode(_component_Button, {
              label: _ctx.$t('btn_save_label'),
              class: "p-button-success",
              icon: "pi pi-save",
              iconPos: "right",
              disabled: _ctx.requestPending,
              onClick: _ctx.save
            }, null, 8, ["label", "disabled", "onClick"])
          ]),
          _createVNode(_component_Divider),
          _createVNode("h2", null, [
            _createTextVNode(_toDisplayString(_ctx.$t("coupon.add.summary_step.refound_coupon")) + " ", 1),
            _createVNode("small", null, "- " + _toDisplayString(_ctx.$t('coupon.add.summary_step.confirmation')), 1)
          ]),
          _createVNode("p", {
            innerHTML: _ctx.dataModel.coupon.description
          }, null, 8, ["innerHTML"]),
          _createVNode(_component_Divider),
          _createVNode("div", _hoisted_4, [
            _createVNode(_component_Button, {
              label: _ctx.$t('btn_step_back_label'),
              class: "p-button-secondary",
              icon: "pi pi-angle-double-left",
              iconPos: "left",
              onClick: _ctx.goBack
            }, null, 8, ["label", "onClick"]),
            _createVNode(_component_Button, {
              label: _ctx.$t('btn_save_label'),
              class: "p-button-success",
              icon: "pi pi-save",
              iconPos: "right",
              disabled: _ctx.requestPending,
              onClick: _ctx.save
            }, null, 8, ["label", "disabled", "onClick"])
          ])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}