import { Coupon } from "./Coupon";
import { TimeSlot } from "./Domain";
import { Service } from "./Service";

export class Order {
    id: number = null;
    service_id: number = null;
    service_date_at: string = null;
    service_time_slot_from_at: string = null;
    service_time_slot_to_at: string = null;

    readonly service: Service = null;
    readonly pos_portfolio_time_slots: TimeSlot[] = [];
    
    status: OrderStatusEnum;
    pos: any;
    feedback_service: number = null;
    feedback_technician: number = null;
    order_code: string = null;
    readonly amount_paid: number = null;
    readonly customer_name: string = null;
    readonly customer_note: string = null;
    readonly customer_id: number = null;
    readonly type: OrderTypeEnum;
    coupon_code: string = null;
    readonly coupon: Coupon = null;
    readonly used_coupon: Coupon = null;
}

export class PosProviderOrder {
    id                  : number;
    status              : OrderStatusEnum;

    pos_id              : number;
    pos_name            : "string";

    provider_id         : number;
    provider_name       : "string";

    gross_amount        : number;
    net_amount          : number;

    service_id          : number;
    service_name        : "string";
    service_date_at     : "string";

    technician_id       : number;
    technician_fullname : "string";

    order_code          : "string";
}

export class ProviderOrder {
    id                  : number;
    status              : OrderStatusEnum;

    pos_id              : number;
    pos_name            : "string";

    gross_amount        : number;
    net_amount          : number;

    service_id          : number;
    service_name        : "string";
    service_date_at     : "string";

    technician_id       : number;
    technician_fullname : "string";
    
    order_code          : "string";
}

export class PosOrder {
    id                  : number;
    status              : OrderStatusEnum;

    provider_id         : number;
    provider_name       : "string";

    gross_amount        : number;
    net_amount          : number;

    service_id          : number;
    service_name        : "string";
    service_date_at     : "string";

    technician_id       : number;
    technician_fullname : "string";

    order_code          : "string";
}

export class PosCustomerOrder {
    id                  : number;
    status              : OrderStatusEnum;

    pos_id              : number;
    pos_name            : "string";

    gross_amount        : number;

    service_id          : number;
    service_name        : "string";
    service_date_at     : "string";

    service_time_slot_from_at   :  "string";
    service_time_slot_to_at     :  "string";

    order_code          : "string";
}

export enum OrderStatusEnum {
    OK001 = "OK001",
    OKEND = "OKEND",
    KOPOS = "KOPOS",
    KOPRV = "KOPRV",
    KOCUS = "KOCUS",
    BOOKED = "BOOKD",
    EXPIRED = "EXPIR",
    REFUNDED = "REFUN"
}

export enum OrderTypeEnum {
     PROVIDER_SERVICE = 'POSPR',
     POS_SINGLE_SERVICE = 'CUPOS',
     POS_RECUR_SERVICE = 'CUPOR',
     POS_PROV_SERVICE = 'CUPOP'
}

export const customerOrderTypes = [
    OrderTypeEnum.POS_SINGLE_SERVICE, 
    OrderTypeEnum.POS_RECUR_SERVICE,
    OrderTypeEnum.POS_PROV_SERVICE
]
