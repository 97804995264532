import { Options, Vue } from "vue-class-component";

import { authStore } from "@/modules/auth/store";
import { Prop } from "vue-property-decorator";
import ChooseTypeStep from "./add_steps/ChooseTypeStep.vue";
import SummaryStep from "./add_steps/SummaryStep.vue";
import RefundCouponStep from "./add_steps/RefundCouponStep.vue";
import PromoCouponStep from "./add_steps/PromoCouponStep.vue";
import { FormPage } from "@components";
import { AddCouponStepEnum } from "@/modules/coupons/router/CouponsRoutesEnum";
import { couponService } from "@services/coupon.service";
import { CouponEvent } from "@/model/api/Coupon";


@Options({
  components: {
    ChooseTypeStep,
    RefundCouponStep,
    PromoCouponStep,
    SummaryStep
  }
})

export default class AddCouponPage extends FormPage {
  @Prop()
  readonly step!: string;

  @Prop()
  readonly id?: number;

  dataModel: CouponEvent = new CouponEvent();
  isValidTimeline = false;

  protected modelFormName = 'dataModel';

  beforeRouteLeave(to, from, next) {
    this.checkBeforeRouteLeave(next);
  }

  get me() {
    return authStore.getters.me;
  }

  get currentTimelineIndex() {
    switch (this.step) {
      case AddCouponStepEnum.CHOOSE_TYPE:
        return 0;
      case AddCouponStepEnum.PROMO_COUPON:
        return 1;
      case AddCouponStepEnum.REFUND_COUPON:
        return 1;
      case AddCouponStepEnum.SUMMARY:
        return 2;
    }
    return -1; // hide timeline
  }

  async created() {
    if (this.step != AddCouponStepEnum.CHOOSE_TYPE) {
      this.goStep(AddCouponStepEnum.CHOOSE_TYPE);
    }

    if (this.id) {
      this.dataModel = await couponService.getByEventId(this.id);
    }

    this.modelFormChanged();
    this.isValidTimeline = true;
  }

  async goStep(step: AddCouponStepEnum) {
    await this.$router.replace({ name: this.$route.name, params: { step: step } });
  }

  onModelFormChanged() {
    this.modelFormChanged();
  }
}
