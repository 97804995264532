import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withKeys as _withKeys, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "step-title" }
const _hoisted_2 = {
  key: 0,
  class: "card"
}
const _hoisted_3 = { class: "step-actions" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = {
  key: 1,
  class: "vertical-form"
}
const _hoisted_6 = { class: "p-field" }
const _hoisted_7 = { for: "codice_ordine" }
const _hoisted_8 = { class: "p-inputgroup" }
const _hoisted_9 = { class: "p-d-flex" }
const _hoisted_10 = { class: "p-field p-col" }
const _hoisted_11 = { class: "p-field p-col" }
const _hoisted_12 = {
  key: 0,
  class: "field_value"
}
const _hoisted_13 = { key: 1 }
const _hoisted_14 = { class: "p-d-flex" }
const _hoisted_15 = { class: "p-field p-col" }
const _hoisted_16 = { class: "p-field p-col" }
const _hoisted_17 = { class: "step-actions" }
const _hoisted_18 = {
  key: 1,
  class: "card"
}
const _hoisted_19 = { class: "p-d-flex" }
const _hoisted_20 = { class: "p-field p-col" }
const _hoisted_21 = { class: "p-d-flex" }
const _hoisted_22 = { class: "p-field p-col" }
const _hoisted_23 = { class: "p-field p-col" }
const _hoisted_24 = {
  key: 0,
  class: "field_value"
}
const _hoisted_25 = { key: 1 }
const _hoisted_26 = { class: "p-d-flex" }
const _hoisted_27 = { class: "p-field p-col" }
const _hoisted_28 = { class: "p-field p-col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Divider = _resolveComponent("Divider")!
  const _component_InfoIcon = _resolveComponent("InfoIcon")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_CurrencyText = _resolveComponent("CurrencyText")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode("label", _hoisted_1, _toDisplayString(_ctx.$t('coupon.add.refund_coupon_step.title')), 1),
    (_ctx.step == 'refund-coupon')
      ? (_openBlock(), _createBlock("div", _hoisted_2, [
          _createVNode("div", _hoisted_3, [
            _createVNode(_component_Button, {
              label: _ctx.$t('btn_step_back_label'),
              class: "p-button-secondary",
              icon: "pi pi-angle-double-left",
              iconPos: "left",
              onClick: _ctx.goChooseType
            }, null, 8, ["label", "onClick"]),
            _createVNode(_component_Button, {
              label: _ctx.$t('btn_step_next_label'),
              class: "p-button-success p-ml-auto",
              icon: "pi pi-angle-double-right",
              iconPos: "right",
              disabled: !_ctx.isNextEnabled,
              onClick: _ctx.goSummary
            }, null, 8, ["label", "disabled", "onClick"])
          ]),
          _createVNode(_component_Divider),
          (_ctx.requestPending)
            ? (_openBlock(), _createBlock("p", _hoisted_4, _toDisplayString(_ctx.$t('loading_label')), 1))
            : (_openBlock(), _createBlock("form", _hoisted_5, [
                _createVNode("div", _hoisted_6, [
                  _createVNode("label", _hoisted_7, [
                    _createTextVNode(_toDisplayString(_ctx.$t('coupon.add.refund_coupon_step.order_code.label')) + " ", 1),
                    _createVNode(_component_InfoIcon, { text: "coupon.add.refund_coupon_step.order_code.label.tooltip" })
                  ]),
                  _createVNode("div", _hoisted_8, [
                    _createVNode(_component_InputText, {
                      id: "codice_ordine",
                      modelValue: _ctx.dataModel.coupon.order.order_code,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.dataModel.coupon.order.order_code = $event)),
                      editorStyle: "height: 100px",
                      name: "order_id",
                      onKeydown: _withKeys(_ctx.searchOrder, ["enter"])
                    }, null, 8, ["modelValue", "onKeydown"]),
                    _createVNode(_component_Button, {
                      icon: "pi pi-search",
                      class: "p-button-warning",
                      onClick: _ctx.searchOrder
                    }, null, 8, ["onClick"])
                  ])
                ]),
                _createVNode("div", _hoisted_9, [
                  _createVNode("div", _hoisted_10, [
                    _createVNode("label", null, _toDisplayString(_ctx.$t('coupons.detail.value')), 1),
                    _createVNode("div", null, [
                      _createVNode(_component_CurrencyText, {
                        value: _ctx.dataModel.coupon.order.amount_paid
                      }, null, 8, ["value"])
                    ])
                  ]),
                  _createVNode("div", _hoisted_11, [
                    _createVNode("label", null, _toDisplayString(_ctx.$t('coupons.detail.customer')), 1),
                    (_ctx.dataModel.coupon.order.is_anonym_customer)
                      ? (_openBlock(), _createBlock("div", _hoisted_12, _toDisplayString(_ctx.$t('coupon.add.refund_coupon_step.anonymous')), 1))
                      : (_openBlock(), _createBlock("div", _hoisted_13, _toDisplayString(_ctx.dataModel.coupon.order.customer_name || '-'), 1))
                  ])
                ]),
                _createVNode("div", _hoisted_14, [
                  _createVNode("div", _hoisted_15, [
                    _createVNode("label", null, _toDisplayString(_ctx.$t('coupons.detail.service')), 1),
                    _createVNode("div", null, _toDisplayString(_ctx.dataModel.coupon.order.service?.name || '-'), 1)
                  ]),
                  _createVNode("div", _hoisted_16, [
                    _createVNode("label", null, _toDisplayString(_ctx.$t('coupons.detail.where')), 1),
                    _createVNode("div", null, _toDisplayString(_ctx.dataModel.coupon.order.pos?.business_name || '-'), 1)
                  ])
                ])
              ])),
          _createVNode(_component_Divider),
          _createVNode("div", _hoisted_17, [
            _createVNode(_component_Button, {
              label: _ctx.$t('btn_step_back_label'),
              class: "p-button-secondary",
              icon: "pi pi-angle-double-left",
              iconPos: "left",
              onClick: _ctx.goChooseType
            }, null, 8, ["label", "onClick"]),
            _createVNode(_component_Button, {
              label: _ctx.$t('btn_step_next_label'),
              class: "p-button-success p-ml-auto",
              icon: "pi pi-angle-double-right",
              iconPos: "right",
              disabled: !_ctx.isNextEnabled,
              onClick: _ctx.goSummary
            }, null, 8, ["label", "disabled", "onClick"])
          ])
        ]))
      : (_openBlock(), _createBlock("div", _hoisted_18, [
          _createVNode("div", _hoisted_19, [
            _createVNode("div", _hoisted_20, [
              _createVNode("label", null, _toDisplayString(_ctx.$t('coupon.add.refund_coupon_step.order_code.label')), 1),
              _createVNode("div", null, _toDisplayString(_ctx.dataModel.coupon.order.order_code|| '-'), 1)
            ])
          ]),
          _createVNode("div", _hoisted_21, [
            _createVNode("div", _hoisted_22, [
              _createVNode("label", null, _toDisplayString(_ctx.$t('coupons.detail.value')), 1),
              _createVNode("div", null, [
                _createVNode(_component_CurrencyText, {
                  value: _ctx.dataModel.coupon.order.amount_paid
                }, null, 8, ["value"])
              ])
            ]),
            _createVNode("div", _hoisted_23, [
              _createVNode("label", null, _toDisplayString(_ctx.$t('coupons.detail.customer')), 1),
              (_ctx.dataModel.coupon.order.is_anonym_customer)
                ? (_openBlock(), _createBlock("div", _hoisted_24, _toDisplayString(_ctx.$t('coupon.add.refund_coupon_step.anonymous')), 1))
                : (_openBlock(), _createBlock("div", _hoisted_25, _toDisplayString(_ctx.dataModel.coupon.order.customer_name || '-'), 1))
            ])
          ]),
          _createVNode("div", _hoisted_26, [
            _createVNode("div", _hoisted_27, [
              _createVNode("label", null, _toDisplayString(_ctx.$t('coupons.detail.service')), 1),
              _createVNode("div", null, _toDisplayString(_ctx.dataModel.coupon.order.service?.name || '-'), 1)
            ]),
            _createVNode("div", _hoisted_28, [
              _createVNode("label", null, _toDisplayString(_ctx.$t('coupons.detail.where')), 1),
              _createVNode("div", null, _toDisplayString(_ctx.dataModel.coupon.order.pos?.business_name || '-'), 1)
            ])
          ])
        ]))
  ], 64))
}