import { Coupon, CouponEvent } from '@/model/api/Coupon';
import { PaginatedResponse } from '@/model/api/PaginatedResponse';
import axios from 'axios';
import { CRUD } from './base/crud';

class CouponService extends CRUD<Coupon, any> {
    readonly endPoint = `coupons`;

    public autocomplete(q: string): Promise<PaginatedResponse<Coupon>> {
        return this.get(`${this.endPoint}/autocomplete`, { params: { q: q } });
    }

    public async fetchCoupons(params?: any) {
        if (params.filters) {
            params.filters = this.getFilterParam(params.filters);
        }

        this.source = axios.CancelToken.source();
        const cancelToken = this.source.token;

        const res = await this.get<PaginatedResponse<Coupon>>(
            `${this.endPoint}/list`, 
            { params, cancelToken }
        );
        return res;
    }

    public async searchCoupons(service_id: number, entity_id: number) {
        this.source = axios.CancelToken.source();
        const cancelToken = this.source.token;

        const res = await this.get<Coupon[]>(
            `${this.endPoint}/available`, 
            { service_id, entity_id, cancelToken }
        )
        return res;
    }

    public async searchCoupon(code: string) {
        this.source = axios.CancelToken.source();
        const cancelToken = this.source.token;

        const res = await this.get<Coupon>(
            `${this.endPoint}/code/${code}/`, 
            { cancelToken }
        );
        
        return res;
    }

    public async fetchIssuedCoupons(params?: any) {
        if (params.filters) {
            params.filters = this.getFilterParam(params.filters);
        }

        this.source = axios.CancelToken.source();
        const cancelToken = this.source.token;

        const res = await this.get<PaginatedResponse<Coupon>>(
            `${this.endPoint}/issued-list`, 
            { params, cancelToken }
        );
        
        return res;
    }

    public async getByEventId(eventId: number) {
        this.source = axios.CancelToken.source();
        const cancelToken = this.source.token;

        const res = await this.get<CouponEvent>(
            `${this.endPoint}/event/${eventId}`, 
            { cancelToken }
        );
        return res;
    }
}

export const couponService = new CouponService();