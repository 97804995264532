import { POSService } from "@/model/api/POS";
import { AttachmentTypeEnum } from "@/model/enums/AttachmentTypeEnum";
import { authStore } from "@/modules/auth/store";
import { POSAddServiceStepEnum } from "@/modules/pos/router/POSRoutesEnum";
import { POSRoutesEnum } from '@/modules/pos/router';
import { posServiceService } from "@services/posService.service";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { CouponEvent, CouponSourceType, CouponStatus, CouponTargetType, CouponType, CouponWhereType, ValueType } from "@/model/api/Coupon";
import { AddCouponStepEnum, CouponsRoutesEnum } from "@/modules/coupons/router/CouponsRoutesEnum";
import { couponService } from "@services/coupon.service";

@Options({
  components: {
  },
  emits: ['goStep', 'modelFormChanged']
})
export default class SummaryStep extends Vue {
  @Prop()
  readonly step!: string;

  @Prop()
  readonly dataModel!: CouponEvent;

  goBack() {
    if (this.dataModel.coupon.type == CouponType.REFUND) {
      this.$emit('goStep', AddCouponStepEnum.REFUND_COUPON);
    } else {
      this.$emit('goStep', AddCouponStepEnum.PROMO_COUPON);
    } 
  }

  fillCouponData() {
    this.dataModel.coupon.value = this.dataModel.coupon.order.amount_paid;
    this.dataModel.coupon.value_type = ValueType.AMOUNT;
    this.dataModel.coupon.source_id = this.me.entity_id;
    this.dataModel.coupon.source_type = CouponSourceType.POS;
    this.dataModel.coupon.target_id = this.dataModel.coupon.order.customer_id;
    this.dataModel.coupon.target_type = CouponTargetType.CUSTOMER;
    this.dataModel.coupon.where_id = this.me.entity_id;
    this.dataModel.coupon.where_type = CouponWhereType.POS;
    this.dataModel.coupon.source_id = this.dataModel.coupon.order.service_id;
    this.dataModel.coupon.source_order_id = this.dataModel.coupon.order.id;
    this.dataModel.coupon.status = CouponStatus.ISSUED;
  }

  get me() {
    return authStore.getters.me;
  }

  async save() {
    this.fillCouponData();

    await this.$waitFor(async () => {
      let coupon = null;
      if (this.dataModel.id) {
        coupon = await couponService.updatePatch(this.dataModel.coupon);
      } else {
        coupon = await couponService.create(this.dataModel.coupon);
      }

      this.$successMessage(this.$t('data_saved_success'));
      this.$emit('modelFormChanged');

      this.$router.push({ name: CouponsRoutesEnum.ISSUED_COUPONS });
    });
  }
}
